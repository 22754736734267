<template>
  <div>
    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      width="500"
      >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          x-small
          depressed
          outlined
          v-bind="attrs"
          v-on="on"
          v-if="profile.id != currentUser.selectedProfile.id"
		  class="rounded-lg py-3"
          >
          <v-icon
            color="red"
			size="18"
            >
            mdi-flag
          </v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          Reportar perfil

          <v-spacer></v-spacer>

          <v-icon
            @click="dialog = false"
            large
            >
            mdi-close-circle-outline
          </v-icon>
        </v-card-title>

        <v-card-text
          class="px-0"
          >
          <v-stepper 
            v-model="stepper"
            flat
            >
            <v-stepper-items>
              <v-stepper-content 
                class="py-0"
                step="1"
                >
                <v-list 
                  >
                  <v-list-item-group
                    color="primary"
                    >
                    <v-list-item
                      v-for="(item, i) in items"
                      :key="i"
                      class="px-0"
                      @click="next(item.value)"
                      >
                      <v-list-item-icon>
                        <v-icon>
                          mdi-chevron-right
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.text"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-stepper-content>

              <v-stepper-content 
                class="py-0"
                step="2"
                >
                <v-textarea
                  outlined
                  solo
                  flat
                  label="Déjanos un comentario..."
                  v-model="attributes.comments"
                  hint="*Opcional"
                  persistent-hint
                  ></v-textarea>

                <div
                  class="d-flex justify-space-between"
                  >
                  <v-btn
                    outlined
                    @click="stepper = 1"
                    >
                    Volver
                  </v-btn>

                  <v-btn
                    color="primary"
                    @click="createReport"
                    >
                    Enviar reporte
                  </v-btn>
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CreateReport } from '@/graphql/mutations/reports'

export default {
  data: () => ({
    stepper: 1,
    dialog: false,
    alert: {
      active: false,
      message: null
    },
    items: [
      {
        text: 'Es una estafa',
        value: 'scam',
      },
      {
        text: 'Contenido sexual o inapropiado',
        value: 'sexual',
      },
      {
        text: 'Drogas o contenido ilegal',
        value: 'illegal',
      },
      {
        text: 'Bullying o acoso',
        value: 'harassment',
      },
      {
        text: 'Violación a la propiedad intelectual',
        value: 'copyright',
      },
      {
        text: 'Suplantación de identidad',
        value: 'impersonating',
      },
    ],
    attributes: {
      reportableType: 'Social::Profile',
      reportableId: null,
      reportType: null,
      comments: null
    }
  }),

  props: {
    profile: {
      required: true,
      type: Object
    }
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  created () {
    this.attributes.reportableId = this.profile.id
  },

  methods: {
    next (value) {
      this.attributes.reportType = value
      this.stepper = 2
    },

    createReport () {
      this.$apollo.mutate({
        mutation: CreateReport,
        variables: {
          input: {
            attributes: this.attributes
          }
        }
      }).then ( res => {
        this.dialog = false
        this.comments = null
        this.stepper = 1
        this.$emit('reload')
      })
    }
  }
}
</script>
